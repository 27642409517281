import React from "react";
import { graphql } from "gatsby";
import { TwoColumnLayout } from "../layouts";
import { StaticImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import {
  Hero,
  ContentBlock,
  Subscribe,
  LogoGrid,
  RightColumnSubscribe,
} from "../components";

import { Headline } from "../components/atoms";

import ogImg from "../images/metadata.png";

const IndexPage = ({ data }) => {
  // content from site metadata
  const { siteUrl } = data.site.siteMetadata;

  return (
    <TwoColumnLayout>
      <Helmet>
        <title>Yes-code | yescode.org</title>
        <meta
          name="description"
          content="Tools that make building software feel like magic."
        />
        <meta name="keywords" content="code, developers, manifesto" />
        <meta property="og:url" content="https://www.yescode.org" />
        <meta property="og:title" content="Yes-code | yescode.org" />
        <meta
          property="og:description"
          content="Tools that make building software feel like magic."
        />
        <meta property="og:type" content="website" />
        <meta property="og:image" content={`${siteUrl}${ogImg}`} />
        <meta property="og:image:width" content="1080" />
        <meta property="og:image:height" content="608" />
        <meta property="og:image:alt" content="Yes-code " />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Yes-code | yescode.org" />
        <meta
          name="twitter:description"
          content="Tools that make building software feel like magic."
        />
        <meta name="twitter:image" content={`${siteUrl}${ogImg}`} />
        <link rel="icon" href="/" />
        <html lang="en" />
      </Helmet>
      <div>
        <Hero />
        <ContentBlock title="Writing software still feels like banging rocks together.">
          <Headline tag="h3">
            In the beginning, we punched ones and zeros – <em>into cards</em>.
            Some might celebrate how far our tools have come, but it’s just{" "}
            <em>not enough</em>.
          </Headline>
          <p>
            We’re burning cycles on solved problems, reimplementing essentials
            like authorization, notifications, ecommerce, feature flagging, and
            recurring billing –{" "}
            <em>none of which are unique to our applications</em>. There are
            hundreds of features like these that we are forced to build over and
            over again.
          </p>
          <p>
            We’re taking on a lot of risk through our naive implementations of
            these domain-layer features. Rolling our own (how hard could it be?)
            opens the door to introducing costly bugs, and adds the burden of
            long-term maintenance.
          </p>
          <p>
            It’s near-impossible for one developer to build every essential
            feature in-house – <em>and do it well</em>. As we add complexity to
            our applications the team must expand in expertise and capacity
            dramatically increasing cost.
          </p>
          <Headline tag="h3">Does it have to be this hard?</Headline>
        </ContentBlock>
        <ContentBlock title="Code should unlock our creative superpowers.">
          <Headline tag="h3">
            To achieve flow and focus on what truly matters, we need
            off-the-shelf building blocks that snap together at the right level
            of abstraction.
          </Headline>
          <p>
            The most impactful code that we write within our applications is the
            implementation of our core value proposition. We dream of a world
            where we can focus on our unique and opinionated features without
            the laborious task of building scaffolding and various underpinnings
            that, while necessary, should be built by someone else.
          </p>
          <Headline tag="h4">
            When we reach for a solution, what we really want is to:
          </Headline>

          <ul>
            <li>Speed up development</li>
            <li>Improve product quality</li>
            <li>Reduce code complexity</li>
          </ul>

          <Headline tag="h3">Yes-code tools give us superpowers.</Headline>
        </ContentBlock>
        <ContentBlock title="Yes-code tools make building software feel like magic.">
          <Headline tag="h3">
            Designed for developers with speed and flexibility in mind, Yes-code
            tools deliver batteries-included solutions to domain-layer problems
            so we can focus on the code that matters in our applications.
          </Headline>
          <Headline tag="h2">The Yes-code Pattern:</Headline>
          <p>
            Every application has dependencies. Make them your strength rather
            than weakness by building with tools that follow the Yes-code
            Pattern.
          </p>
          <Headline tag="h4">Requirements</Headline>
          <p>The core of what makes a Yes-code tool.</p>

          <ol>
            <li>Operate from within the codebase.</li>
            <li>Solve a discrete, domain-layer problem.</li>
            <li>Provide an administration and control plane.</li>
            <li>Expose a stable collection of remixable primitives.</li>
            <li>Assemble customizable kits for the most common use cases.</li>
            <li>Make the first-run experience plug-and-play.</li>
          </ol>

          <Headline tag="h4">Recommendations</Headline>
          <p>Best practices for Yes-code tools.</p>

          <ul>
            <li>Teach users about the domain, edge cases, and compliance.</li>
            <li>
              Extract opinionated ideas and insights from production systems.
            </li>
            <li> Embrace iterative configuration and deployment.</li>
            <li>Include disposable sandbox environments.</li>
            <li>Expose detailed audit logs for validation and testing.</li>
            <li> Architect for robustness and reliability at scale. </li>
            <li> Provide quality documentation and responsive support. </li>
          </ul>

          <Headline tag="h4">Considerations</Headline>
          <p>Important decisions for Yes-code tools.</p>

          <ul>
            <li>Build to be language- and platform- agnostic.</li>
            <li>Allow developers to use their preferred tools.</li>
            <li>Rely on trusted infrastructure and be transparent about it.</li>
            <li>
              Release source code for SDKs, formats, and interfaces as open
              source.
            </li>
          </ul>
        </ContentBlock>
        {/* <LogoGrid /> */}
        <ContentBlock
          title="Stay in the loop."
          subtitle="Here’s what you can expect:"
        >
          <div className="arrow-right">
            <StaticImage
              src="../images/arrow-right.png"
              alt="arrow-right"
              placeholder="blurred"
              width={200}
            />
          </div>
          <div className="arrow-down">
            <StaticImage
              src="../images/arrow-down.png"
              alt="arrow-down"
              placeholder="blurred"
              width={60}
            />
          </div>

          <ul>
            <li>Hear about new Yes-code tools.</li>
            <li>Stories from the innovators behind popular Yes-code tools.</li>
            <li>
              Get inspiration from developers using yes-code tools in
              production.
            </li>
          </ul>
        </ContentBlock>
        <ContentBlock
          title="We want to hear from you."
          subtitle="Did we miss something? Do you know someone we should meet?"
        >
          <p>
            Send us a note at{" "}
            <a href="mailto:hello@yescode.org">hello@yescode.org</a> 💌
          </p>
        </ContentBlock>
      </div>
      <RightColumnSubscribe>
        <Subscribe />
      </RightColumnSubscribe>
    </TwoColumnLayout>
  );
};

export default IndexPage;

export const query = graphql`
  query Home {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
